import React, { FC, useEffect, useRef } from 'react'
import Link from 'next/link'
import { Logo } from '@components/ui'
import { Cross, ChevronLeft } from '@components/icons'
import { UserNav } from '@components/common'
import cn from 'clsx'
import s from './SidebarLayout.module.scss'
import gsap from 'gsap/dist/gsap'

type ComponentProps = { className?: string } & (
  | { handleClose: () => any; handleBack?: never }
  | { handleBack: () => any; handleClose?: never }
)

const SidebarLayout: FC<ComponentProps> = ({
  children,
  className,
  handleBack,
  handleClose,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const animateClose = () => {
    const tl = gsap.timeline({ paused: true })
    tl.to(ref.current, {
      xPercent: 103,
    })

    tl.set('.sidebar-background', { opacity: 0 })

    tl.play().then(() => {
      handleClose && handleClose()
    })
  }

  useEffect(() => {
    const tl = gsap.timeline({ paused: true })
    tl.set('.sidebar-background', { opacity: 1 })
    tl.fromTo(
      ref.current,
      {
        xPercent: 103,
      },
      {
        xPercent: 0,
      }
    )

    tl.play()
  }, [])

  return (
    <div className={cn(s.root, className)} ref={ref}>
      <header className={s.header}>
        <Link href="/">
          <a className={s.logo} aria-label="Logo">
            <Logo className={s.logoImage} color={'DARK'} />
          </a>
        </Link>
        {handleClose && (
          <button
            onClick={animateClose}
            aria-label="Close"
            className={s.button}
          >
            <Cross className={s.icon} />
          </button>
        )}
        {handleBack && (
          <button
            onClick={handleBack}
            aria-label="Go back"
            className={s.button}
          >
            <ChevronLeft className={s.icon} />
            <span className={s.iconText}>Back</span>
          </button>
        )}

        {/* <UserNav /> */}
      </header>
      <div className={s.container}>{children}</div>
    </div>
  )
}

export default SidebarLayout
