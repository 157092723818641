const Logo = ({ color='DARK', className = '', ...props }) => (
  <svg
    width="222"
    height="54"
    viewBox="0 0 222 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    {...props}
  >
    <path
      d="M62.096 38.7117L59.0698 52.7815H20.476L31.5196 1.17902H47.9082L39.8515 38.751H62.096V38.7117Z"
      fill={color == "DARK" ? "#FFFFFF" : "#000000"}
    />
    <path
      d="M64.7291 24.6419C67.8339 9.5109 78.406 0 95.0304 0C111.616 0 122.227 11.0829 118.454 29.2794C115.113 45.3143 104.463 53.9999 87.8382 53.9999C71.1745 54.0392 60.9562 42.8383 64.7291 24.6419ZM101.712 27.7074C103.834 17.7642 99.2356 13.9126 93.1439 13.9126C87.0522 13.9126 83.1221 17.7642 81.3142 26.096C79.1527 36.2357 83.633 40.0873 89.7247 40.0873C95.8164 40.0873 99.8644 36.2357 101.712 27.7074Z"
      fill={color == "DARK" ? "#FFFFFF" : "#000000"}
    />
    <path
      d="M158.816 34.9388L165.498 52.7815H148.008L143.45 38.0043H137.279L134.096 52.7815H117.707L128.751 1.17902H150.877C165.537 1.17902 172.611 9.94319 170.096 21.8515C168.642 28.4934 163.965 32.6986 158.816 34.9388ZM140.109 24.524H147.812C151.153 24.524 153.55 23.0698 154.139 20.1615C154.729 17.2532 153.275 14.6593 149.227 14.6593H142.27L140.109 24.524Z"
      fill={color == "DARK" ? "#FFFFFF" : "#000000"}
    />
    <path
      d="M192.537 15.2096L191.436 20.2794H215.449L212.541 33.8383H188.528L187.467 38.7117H213.484L210.458 52.7815H168.131L179.135 1.17902H221.463L218.436 15.2489H192.537V15.2096Z"
      fill={color == "DARK" ? "#FFFFFF" : "#000000"}
    />
    <path
      opacity="0.5"
      d="M19.2183 1.17902L8.17462 52.7815H20.4759L31.5196 1.17902H19.2183Z"
      fill={color == "DARK" ? "#FFFFFF" : "#000000"}
    />
    <path
      opacity="0.3"
      d="M11.0043 1.17902L0 52.7815H8.17466L19.2183 1.17902H11.0043Z"
      fill={color == "DARK" ? "#FFFFFF" : "#000000"}
    />
  </svg>
)

export default Logo
