import cn from 'clsx'
import Link from 'next/link'
import { FC, SyntheticEvent, useRef, useState, useEffect } from 'react'
import s from './SetFittersSidebarView.module.scss'

import { Button, Text } from '@components/ui'
import { useUI } from '@components/ui/context'
import { Bag, Cross, Check } from '@components/icons'
import useCart from '@framework/cart/use-cart'
import usePrice from '@framework/product/use-price'
import SidebarLayout from '@components/common/SidebarLayout'
import { useGlobalContentContext } from '@context/GlobalContentContext'
import { gsap } from 'gsap/dist/gsap'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import { stripTags } from '@utils/typography'
import useUpdateItem from '@framework/cart/use-update-item'
import { useAddItem } from '@framework/cart'
import useRemoveItem from '@framework/cart/use-remove-item'
import { set } from 'date-fns'
import commerce from '@lib/api/commerce'

if (typeof window !== 'undefined') {
  // Due to SSR we can only register when we are in the browser
  gsap.registerPlugin(ScrollToPlugin)
}

const SetFittersSidebarView: FC = () => {
  const { closeSidebar, openSidebar, setSidebarView, displaySidebar } = useUI()
  const { data, isLoading, isEmpty } = useCart()

  const item = data?.lineItems[0]

  const removeItem = useRemoveItem()
  const addItem = useAddItem()

  const [showCollapsed, setShowCollapsed] = useState<string | null>(null)
  const [formValidated, setFormValidated] = useState(false)
  const [fitterContent, setFitterContent] = useState('')
  const globalData = useGlobalContentContext()

  const product = globalData.data.loretwo_product

  const handleClose = () => closeSidebar()
  const goToCheckout = () => setSidebarView('CHECKOUT_VIEW')

  const handleBackToCart = () => {
    closeSidebar()
    setSidebarView('CART_VIEW')
    openSidebar()
  }

  const updateFormData = async (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.target.form)
    if (e.target.value === 'Nominate Fitter') {
      setShowCollapsed('nominate')
      window.requestAnimationFrame(() => {
        gsap.to('.sidebar', {
          scrollTo: { y: '#nominate', offsetY: 150 },
        })
      })
    } else if (e.target.value === 'Select Fitter') {
      setShowCollapsed('select-fitter')
      window.requestAnimationFrame(() => {
        gsap.to('.sidebar', {
          scrollTo: { y: '#select-fitter', offsetY: 150 },
        })
      })
    }

    if (formData.get('fitter-select') == 'Select Fitter') {
      setFormValidated(true)
    } else if (
      formData.get('fitter-store-name-1') &&
      formData.get('fitter-postcode-1') &&
      formData.get('fitter-email-1') &&
      formData.get('fitter-phone-1')
    ) {
      if (formData.get('fitter-store-name-2')) {
        if (
          formData.get('fitter-postcode-2') &&
          formData.get('fitter-email-2') &&
          formData.get('fitter-phone-2')
        ) {
          setFormValidated(true)
        } else {
          setFormValidated(false)
        }
      } else {
        setFormValidated(true)
      }
    } else {
      setFormValidated(false)
    }

    const nominatedFitter2 = formData.get('fitter-store-name-2')
      ? `\nNominated Fitter  #2:\n
Store name: ${formData.get('fitter-store-name-2')}\n
Postcode: ${formData.get('fitter-postcode-2')}\n
Email: ${formData.get('fitter-email-2')}\n
Phone: ${formData.get('fitter-phone-2')}\n
Person of Interest: ${formData.get('fitter-person-of-interest-2')}\n`
      : ''

    const fitterContentText =
      formData.get('fitter-select') == 'Select Fitter'
        ? `Selected Fitter`
        : `Nominated Fitter #1:\n
Store name: ${formData.get('fitter-store-name-1')}\n
Postcode: ${formData.get('fitter-postcode-1')}\n
Email: ${formData.get('fitter-email-1')}\n
Phone: ${formData.get('fitter-phone-1')}\n
Person of Interest: ${formData.get('fitter-person-of-interest-1')}\n
${nominatedFitter2}`

    setFitterContent(fitterContentText)
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (formValidated && fitterContent && item) {
      setFormValidated(false)
      if (!product) {
        return
      }

      await removeItem({
        id: item.id,
      })

      const currentOptions =
        item.options?.map((opt) => {
          return {
            option_id: opt.nameId,
            name: opt.name,
            option_value: opt.value,
          }
        }) || []

      const fitterOption = product.options.find(
        (opt) => opt.displayName.toLowerCase() === 'fitter'
      )

      // Options are on cart v3. Types are not up to date with this.
      const itemOptions = currentOptions.concat([
        {
          // @ts-ignore
          option_id: fitterOption.id,
          option_value: fitterContent,
        },
      ])

      await addItem({
        productId: item.productId,
        variantId: item.variantId,
        // @ts-ignore
        optionSelections: itemOptions,
        quantity: item.quantity,
      })

      window.location.href = '/checkout'
    }
  }

  const error = null
  const success = null

  return (
    <SidebarLayout
      className={cn(s.root, {
        [s.empty]: error || success || isLoading || isEmpty,
      })}
      handleClose={handleClose}
    >
      <div className={s.findFitterContainer}>
        <Button
          onClick={handleBackToCart}
          className={s.backToCart}
          variant="none"
        >
          Back to Cart
        </Button>

        <Text variant="sectionHeading">
          {globalData.data.sidebar.find_fitter_sidebar_title}
        </Text>
        <Text html={globalData.data.sidebar.find_fitter_sidebar_detail} />

        <div className={s.fittersContainer}>
          <form className={s.fittersForm} onChange={updateFormData}>
            <div className={s.collapseHeader}>
              <div className={s.selectTrigger}>
                <input
                  type="radio"
                  id={`select-fitter`}
                  name={`fitter-select`}
                  value={`Select Fitter`}
                />
                <label htmlFor={`select-fitter`}>
                  <Text className={s.fitterTitle}>
                    I’ve got a scanning partner near me
                  </Text>
                </label>
              </div>
            </div>

            <div className={s.fitterSuggestContainer}>
              <div className={s.collapseHeader}>
                <div className={s.suggestTrigger}>
                  <input
                    type="radio"
                    id={`nominate`}
                    name={`fitter-select`}
                    value={`Nominate Fitter`}
                  />
                  <label htmlFor={`nominate`}>
                    <Text className={s.fitterTitle}>
                      I don’t see a scanning partner near me
                    </Text>
                  </label>
                </div>
              </div>
              <div
                className={cn(s.collapseBody, {
                  [s.active]: showCollapsed == 'nominate',
                })}
              >
                <Text
                  html={
                    globalData.data.sidebar.find_fitter_sidebar_suggest_detail
                  }
                />
                <div>
                  <Text className={s.choiceTitle}>Choice #1</Text>
                  <input
                    type="text"
                    name="fitter-store-name-1"
                    id="fitter-store-name-1"
                    placeholder="Store Name*"
                  />
                  <input
                    type="text"
                    name="fitter-postcode-1"
                    id="fitter-postcode-1"
                    placeholder="Postal Code*"
                  />
                  <input
                    type="email"
                    name="fitter-email-1"
                    id="fitter-email-1"
                    placeholder="Email*"
                  />
                  <input
                    type="text"
                    name="fitter-phone-1"
                    id="fitter-phone-1"
                    placeholder="Phone*"
                  />
                  <input
                    type="text"
                    name="fitter-person-of-interest-1"
                    id="fitter-person-of-interest-1"
                    placeholder="Person of interest"
                  />
                </div>
                <div>
                  <Text className={s.choiceTitle}>Choice #2</Text>
                  <input
                    type="text"
                    name="fitter-store-name-2"
                    id="fitter-store-name-2"
                    placeholder="Store Name"
                  />
                  <input
                    type="text"
                    name="fitter-postcode-2"
                    id="fitter-postcode-2"
                    placeholder="Postal Code"
                  />
                  <input
                    type="email"
                    name="fitter-email-2"
                    id="fitter-email-2"
                    placeholder="Email"
                  />
                  <input
                    type="text"
                    name="fitter-phone-2"
                    id="fitter-phone-2"
                    placeholder="Phone"
                  />
                  <input
                    type="text"
                    name="fitter-person-of-interest-2"
                    id="fitter-person-of-interest-2"
                    placeholder="Person of interest"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className={s.totalsContainer}>
        <div>
          <Button
            href="/checkout"
            Component="a"
            width="100%"
            className={cn(s.checkoutButton, {
              [s.checkoutButtonActive]: formValidated,
            })}
            disabled={!formValidated}
            onClick={handleSubmit}
          >
            Proceed to Checkout
          </Button>
        </div>
      </div>
    </SidebarLayout>
  )
}

export default SetFittersSidebarView
