import React, { useState, useEffect } from 'react'
import Image, { ImageProps } from 'next/image'
import { isMobileWidth, isDesktopWidth } from '@utils/helpers'
import { useWindowSize } from '@hooks/useWindowSize'
import { useTimeout } from 'usehooks-ts'
// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
// import { ScrollSmoother } from 'gsap/dist/ScrollSmoother'
import cn from 'clsx'
import style from './CustomImage.module.scss'
import { RequestFile } from '@generated/model/models'
// if (typeof window !== 'undefined') {
//   // Due to SSR we can only register when we are in the browser
//   gsap.registerPlugin(ScrollTrigger, ScrollSmoother)
// }

export type CustomImageProps = {
  image_height?: number
  image_width?: number
  image: RequestFile
  mobile_image_height?: number
  mobile_image_width?: number
  mobile_image?: RequestFile | null | undefined
  alt?: string
  priority?: boolean
  objectFit?: string
  objectPosition?: string
  sizes?: string
  quality?: number
  className?: string
}

export type CustomImageType = {
  data: CustomImageProps
  loadedCallback?: () => void
} & Omit<ImageProps, 'src'>

const CustomImage = ({ ...props }: CustomImageType) => {
  const phoneImageSrc = props.data?.mobile_image
    ? (props.data.mobile_image as string)
    : undefined
  const phoneWidth = props.data.mobile_image_width
    ? props.data.mobile_image_width
    : undefined
  const phoneHeight = props.data.mobile_image_height
    ? props.data.mobile_image_height
    : undefined

  const windowSize = useWindowSize()
  const isMobile = isMobileWidth(windowSize.width)
  const isDesktop = isDesktopWidth(windowSize.width)

  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const [src, setSrc] = useState<string | undefined>(undefined)
  const [width, setWidth] = useState<number | undefined>(props.data.image_width)
  const [height, setHeight] = useState<number | undefined>(
    props.data.image_height
  )
  const [layout, setLayout] = useState<ImageProps['layout']>('responsive')
  const [loading, setLoading] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (isMobile && phoneImageSrc && containerRef.current) {
      setSrc(phoneImageSrc)
    } else if (isDesktop || !phoneImageSrc) {
      setSrc(props.data.image as string)
    }
  }, [isMobile, isDesktop, phoneImageSrc, props.data.image])

  useEffect(() => {
    if (props.layout === 'fill') {
      setWidth(undefined)
    } else if (isMobile && phoneImageSrc && phoneWidth) {
      setWidth(phoneWidth)
    } else if (isDesktop || !phoneImageSrc) {
      setWidth(props.data.image_width)
    }
  }, [
    isMobile,
    isDesktop,
    phoneImageSrc,
    src,
    props.layout,
    props.data.image_width,
    phoneWidth,
  ])

  useEffect(() => {
    if (props.layout === 'fill') {
      setHeight(undefined)
    } else if (isMobile && phoneImageSrc && containerRef.current) {
      setHeight(phoneHeight)
    } else if (isDesktop || !phoneImageSrc) {
      setHeight(props.data.image_height)
    }
  }, [
    isMobile,
    isDesktop,
    phoneImageSrc,
    src,
    props.layout,
    props.data.image_height,
    phoneHeight,
  ])

  const handleLoadingComplete = () => {
    // Do nothing
  }

  // Default layout to respsonsive
  useEffect(() => {
    const layoutValue = props.layout
      ? props.layout
      : width && height
      ? 'responsive'
      : 'fill'

    setLayout(layoutValue)
  }, [width, height])

  // Set container styles based on layout
  const containerStyle =
    props.layout == 'responsive' || !props.layout
      ? style.containerBlock
      : style.container

  const sizes = props.sizes ? props.sizes : undefined

  // Set loading to eager on idle

  //   useTimeout(() => {
  //     setLoading(true)
  //   }, 4000)

  return (
    <div className={containerStyle} ref={containerRef} data-layout={layout}>
      {src && (
        <Image
          className={cn(props.className, {
            [style.hasMobilSrc]: phoneImageSrc,
          })}
          src={src}
          alt={props.data.alt}
          layout={layout}
          lazyBoundary="2000px"
          objectFit={props.objectFit ? props.objectFit : undefined}
          objectPosition={props.objectPosition ? props.objectPosition : undefined}
          width={width}
          height={height}
          sizes={sizes}
          priority={props.priority ? props.priority : loading}
          quality={props.priority ? 85 : props.quality ? props.quality : 82}
          onLoadingComplete={handleLoadingComplete}
        />
      )}
    </div>
  )
}

export default CustomImage
