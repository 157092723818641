import cn from 'clsx'
import React, { FC } from 'react'
import s from './Container.module.scss'
import { useUI } from '../context'

interface ContainerProps {
  className?: string
  children?: any
  el?: HTMLElement
  clean?: boolean
  fixedWidth?: boolean
  fullWidth?: boolean
}

const Container: FC<ContainerProps> = ({
  children,
  className,
  el = 'div',
  clean = false, // Full Width Screen
  fixedWidth,
  fullWidth,
}) => {
  const { containerFixedWidth } = useUI()

  const rootClassName = cn(className, {
    [s.root]: !clean,
    [s.fixedWidth]: fullWidth ? false : fixedWidth || containerFixedWidth,
  })

  let Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> =
    el as any

  return <Component className={rootClassName}>{children}</Component>
}

export default Container
