const Menu = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // className="h-6 w-6"
      height="23"
      width="38"
      fill="none"
      viewBox="0 0 38 23"
      strokeWidth="2"
      stroke="currentColor"
      {...props}
    >
      <line y1="11.1162" x2="38" y2="11.1162" />
      <line y1="21.1162" x2="38" y2="21.1162" />
      <line y1="1.11621" x2="38" y2="1.11621" />
    </svg>
  )
}

export default Menu
