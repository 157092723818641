const Cross = ({ ...props }) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      shapeRendering="geometricPrecision"
      {...props}
    >
      <path d="M26 1L1 26" />
      <path d="M1 1L26 26" />
    </svg>
  )
}

export default Cross
