import { FC } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import cn from 'clsx'
import s from './Navbar.module.scss'
import NavbarRoot from './NavbarRoot'
import { Logo, Container, Button } from '@components/ui'
import { useUI } from '@components/ui/context'
import { Searchbar, UserNav } from '@components/common'

interface Link {
  href: string
  label: string
}

interface NavbarProps {
  links?: Link[]
}

const Navbar: FC<NavbarProps> = ({ links }) => {
  const { pageTheme } = useUI()
  const { asPath } = useRouter()

  return (
    <NavbarRoot>
      <Container
        clean
        className={cn(s.container, {
          
          [s.darkMode]: pageTheme == 'LIGHT',
        })}
      >
        <div className={s.nav}>
          <div className={s.links}>
            <Link href="/">
              <a className={s.logo} aria-label="Logo">
                <Logo className={s.logoImage} color={pageTheme} />
              </a>
            </Link>
            <nav className={s.navMenu}>
              {links?.map((l, index: number) => (
                <Link href={l.href} key={l.href}>
                  {index == links.length - 1 ? (
                    <Button variant={pageTheme == 'LIGHT' ? "blackNaked" : "naked"} className={cn({[s.buttonActive]: asPath == l.href})}>
                      <a className={cn(s.link, {[s.active]: asPath == l.href})}>{l.label}</a>
                    </Button>
                  ) : (
                    <a className={cn(s.link, {[s.active]: asPath == l.href})}>{l.label}</a>
                  )}
                </Link>
              ))}
            </nav>
          </div>

          <div className={s.accountContainer}>
            <UserNav />
          </div>
        </div>
      </Container>
    </NavbarRoot>
  )
}

export default Navbar
