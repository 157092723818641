import cn from 'clsx'
import Link from 'next/link'
import { FC } from 'react'
import s from './CartSidebarView.module.scss'
import CartItem from '../CartItem'
import { Button, Text } from '@components/ui'
import { useUI } from '@components/ui/context'
import { Bag, Cross, Check } from '@components/icons'
import useCart from '@framework/cart/use-cart'
import usePrice from '@framework/product/use-price'
import SidebarLayout from '@components/common/SidebarLayout'

const CartSidebarView: FC = () => {
  const { closeSidebar, setSidebarView, openSidebar } = useUI()
  const { data, isLoading, isEmpty } = useCart()

  const { price: subTotal } = usePrice(
    data && {
      amount: Number(data.subtotalPrice),
      currencyCode: data.currency.code,
    }
  )
  const { price: total } = usePrice(
    data && {
      amount: Number(data.totalPrice),
      currencyCode: data.currency.code,
    }
  )
  const handleClose = () => closeSidebar()
  const goToCheckout = () => setSidebarView('CHECKOUT_VIEW')

  const goToSetFitters = () => {
    closeSidebar()
    setSidebarView('SET_FITTER_VIEW')
    openSidebar()
  }

  const error = null
  const success = null

  return (
    <SidebarLayout
      className={cn({
        [s.empty]: error || success || isLoading || isEmpty,
      })}
      handleClose={handleClose}
    >
      {isLoading || isEmpty ? (
        <div className={s.container}>
          <span className={s.iconContainer}>
            <Bag className={s.bag} />
          </span>
          <h2 className={s.title}>Your cart is empty</h2>
          <p className={s.description}></p>
        </div>
      ) : error ? (
        <div className={s.container}>
          <span className={s.iconContainer}>
            <Cross width={24} height={24} />
          </span>
          <h2 className={s.title}>
            We couldn’t process the purchase. Please check your card information
            and try again.
          </h2>
        </div>
      ) : success ? (
        <div className={s.container}>
          <span className={s.iconContainer}>
            <Check />
          </span>
          <h2 className={s.title}>Thank you for your order.</h2>
        </div>
      ) : (
        <>
          <div className={s.cartContainer}>
            <Link href="/cart">
              <a>
                <Text variant="sectionHeading" onClick={handleClose}>
                  My Cart
                </Text>
              </a>
            </Link>
            <ul className={s.lineItemsList}>
              {data!.lineItems.map((item: any) => (
                <CartItem
                  key={item.id}
                  item={item}
                  currencyCode={data!.currency.code}
                />
              ))}
            </ul>
          </div>

          <div className={s.totalsContainer}>
            <ul className={s.totalsList}>
              <li className={s.totalItem}>
                <span>Subtotal</span>
                <span>{subTotal}</span>
              </li>
              <li className={s.totalItem}>
                <span>Taxes</span>
                <span>Calculated at checkout</span>
              </li>
              <li className={s.totalItem}>
                <span>Shipping</span>
                <span className="">FREE</span>
              </li>
            </ul>
            <div className={s.total}>
              <span>Total</span>
              <span>{total}</span>
            </div>
            <div>
              <Button onClick={goToSetFitters} width="100%">
                Find a fitter
              </Button>
            </div>
          </div>
        </>
      )}
    </SidebarLayout>
  )
}

export default CartSidebarView
