import React, { FC } from 'react'
import Image from 'next/image'

type FadeProps = {
  className?: string
  variant?: 'blue' | undefined
}
const Fade = ({ className, variant }: FadeProps) => {
  if (variant == 'blue') {
    return (
      <svg
        width="1440"
        height="1219"
        viewBox="0 0 1440 1219"
        preserveAspectRatio="none"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <defs>
          <linearGradient
            id="paint0_linear_2542_565"
            x1="720"
            y1="0"
            x2="720"
            y2="1219"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#003462" stopOpacity="0" />
            <stop offset="1" stopColor="#003462" />
          </linearGradient>
        </defs>
        <rect width="1440" height="1219" fill="url(#paint0_linear_2542_565)" />
      </svg>
    )
  } else {
    return (
      <div className={className}>
        <Image src="/fade.svg" width="894" height="246" layout="responsive" />
      </div>
    )
  }
}

export default React.memo(Fade)
