import { FC, useEffect } from 'react'
import cn from 'clsx'
import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import type { Page } from '@commerce/types/page'
import getSlug from '@lib/get-slug'
import { Github, Vercel } from '@components/icons'
import { Logo, Container, Text, Fade } from '@components/ui'
import { I18nWidget } from '@components/common'
import { useGlobalContentContext } from '@context/GlobalContentContext'
import { useUI } from '@components/ui/context'

import s from './Footer.module.scss'

interface Props {
  className?: string
  children?: any
  pages?: Page[]
}

const links = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Terms & Conditions',
    url: '/terms',
  },
  {
    name: 'Privacy Policy',
    url: '/privacy',
  },
]

const Footer: FC<Props> = ({ className, pages }) => {
  // const { sitePages } = usePages(pages)

  const { data } = useGlobalContentContext()

  const { displayFooter } = useUI()
  const rootClassName = cn(s.root, className)

  useEffect(() => {
    const loadKlaviyo = () => {
      const existingScript = document.getElementById('klaviyo-signup')
      if (!existingScript) {
        const script = document.createElement('script')

        script.src =
          'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=QSHdkN'
        script.id = 'klaviyo-signup'

        document.body.appendChild(script)
      }
    }
    loadKlaviyo()
  }, [])

  return (
    <footer className={cn(rootClassName, { [s.hideFooter]: !displayFooter })}>
      <Container>
        <div className={s.fadeContainer}>
          <Fade className={s.fade} variant="blue" />
        </div>
        <div className={s.grid}>
          <div className={s.col1}>
            <Logo />
          </div>
          <div className={s.col2}></div>
          <div className={s.col3}>
            <div className={s.newsletterContainer}>
              <Text className={s.newsletterTitle}>{'Newsletter Sign Up'}</Text>
              <div className={s.newsletter}>
                <div className="klaviyo-form-XTBvUE"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={cn(s.grid, s.gridBottom)}>
          <div className={s.col1}>
            <Text className={s.detail} html={data.footer?.detail} />

            <div className={s.detailLinks}>
              <Link href={links[1].url}>
                <a>
                  <Text variant="span">{links[1].name}</Text>
                </a>
              </Link>
              <Link href={links[2].url}>
                <a>
                  <Text variant="span">{links[2].name}</Text>
                </a>
              </Link>
            </div>
            <Text
              className={s.copywrite}
              html={data.footer?.copywrite + ' ' + new Date().getFullYear()}
            />
          </div>
          <div className={s.col2}>
            <span className={s.powered}>Powered by </span>
            <span className={s.morphic}>
              <Image
                src="/morphic.svg"
                layout="responsive"
                width={109}
                height={20}
                alt="Morphic"
              />
            </span>
          </div>
          <div className={cn(s.col3, s.social)}>
            {data.social_media?.map((social) => (
              <Link href={social.url} key={`social-${social.name}`}>
                <a target="_blank" rel="noreferrer">
                  <Text variant="span">{social.name}</Text>
                </a>
              </Link>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  )
}

// function usePages(pages?: Page[]) {
//   const { locale } = useRouter()
//   const sitePages: Page[] = []

//   if (pages) {
//     pages.forEach((page) => {
//       const slug = page.url && getSlug(page.url)
//       if (!slug) return
//       if (locale && !slug.startsWith(`${locale}/`)) return
//       sitePages.push(page)
//     })
//   }

//   return {
//     sitePages: sitePages.sort(bySortOrder),
//   }
// }

// Sort pages by the sort order assigned in the BC dashboard
function bySortOrder(a: Page, b: Page) {
  return (a.sort_order ?? 0) - (b.sort_order ?? 0)
}

export default Footer
