const ChevronRight = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="28"
      viewBox="0 0 18 28"
      strokeLinejoin="round"
      stroke="currentColor"
      fill="none"
      shapeRendering="geometricPrecision"
      {...props}
    >
      <path
        d="M2 1.5L15 14L2 26.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ChevronRight
