import cn from 'clsx'
import s from './Sidebar.module.scss'
import { useEffect, useRef } from 'react'
import { useUI } from '@components/ui/context'

import gsap from 'gsap/dist/gsap'

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
interface SidebarProps {
  children: any
  onClose: () => void
}

const Sidebar: React.FC<SidebarProps> = ({ children, onClose }) => {
  const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const backgroundRef = useRef() as React.MutableRefObject<HTMLDivElement>

  const onKeyDownSidebar = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }

  const { displaySidebar } = useUI()

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.focus()
    }

    const contentElement = contentRef.current

    if (contentElement) {
      disableBodyScroll(contentElement, { reserveScrollBarGap: true })
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  useEffect(() => {
    if (displaySidebar) {
      const tl = gsap.timeline({ paused: true })
      tl.fromTo(
        contentRef.current,
        { xPercent: 103 },
        {
          xPercent: 0,
          duration: 0,
        }
      )
      tl.to(backgroundRef.current, { opacity: 1, duration: 0 }, '<')

      tl.play()

      return () => {
        tl.kill()
      }
    }
    // gsap.to()
  }, [displaySidebar])

  return (
    <div
      className={cn(s.root)}
      ref={sidebarRef}
      onKeyDown={onKeyDownSidebar}
      tabIndex={1}
    >
      <div className={s.inner}>
        <div
          className={cn(s.backdrop, 'sidebar-background')}
          onClick={onClose}
          ref={backgroundRef}
        />
        <section className={s.container}>
          <div className={s.containerInner}>
            <div className={cn(s.sidebar, 'sidebar')} ref={contentRef}>
              {children}
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Sidebar
