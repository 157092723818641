import React, {
  FunctionComponent,
  JSXElementConstructor,
  CSSProperties,
} from 'react'
import cn from 'clsx'
import s from './Text.module.scss'

interface TextProps {
  variant?: Variant
  className?: string
  style?: CSSProperties
  children?: React.ReactNode | any
  html?: string
  onClick?: () => any
}

type Variant =
  | 'heading'
  | 'body'
  | 'pageHeading'
  | 'sectionHeading'
  | 'sectionHeading2'
  | 'sectionHeadingV2'
  | 'smallText'
  | 'smallTextSmall'
  | 'contentHeading'
  | 'text-3'
  | 'span'

const Text: FunctionComponent<TextProps> = ({
  style,
  className = '',
  variant = 'body',
  children,
  html,
  onClick,
}) => {
  const componentsMap: {
    [P in Variant]: React.ComponentType<any> | string
  } = {
    body: 'div',
    heading: 'h1',
    pageHeading: 'h1',
    sectionHeading: 'h2',
    sectionHeading2: 'h2',
    sectionHeadingV2: 'h2',
    contentHeading: 'h3',
    smallText: 'div',
    smallTextSmall: 'div',
    'text-3': 'div',
    span: 'span',
  }

  const Component:
    | JSXElementConstructor<any>
    | React.ReactElement<any>
    | React.ComponentType<any>
    | string = componentsMap![variant!]

  const htmlContentProps = html
    ? {
        dangerouslySetInnerHTML: { __html: html },
      }
    : {}

  return (
    <Component
      className={cn(
        s.root,
        {
          [s.heading]: variant === 'heading',
          [s.pageHeading]: variant === 'pageHeading',
          [s.sectionHeading]: variant === 'sectionHeading',
          [s.sectionHeading2]: variant === 'sectionHeading2',
          [s.sectionHeadingV2]: variant === 'sectionHeadingV2',
          [s.contentHeading]: variant === 'contentHeading',
          [s.smallText]: variant === 'smallText',
          [s.smallTextSmall]: variant === 'smallTextSmall',
          [s.text3]: variant === 'text-3',
        },
        className
      )}
      onClick={onClick}
      style={style}
      {...htmlContentProps}
    >
      {children}
    </Component>
  )
}

export default Text
